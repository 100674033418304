































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Cas extends Vue {
  @Prop()
  private redirect!: string;

  private authenticating = true;

  private async mounted() {
    try {
      const user = await this.$auth.getUser();

      await this.sleep(200);
      if (user) {
        if (!(await this.$auth.isAuthenticated())) {
          window.localStorage.setItem("redirect", this.redirect);
          await this.$auth.login();
          return;
        }
        this.$auth.unhookRouter();
        const redirect = window.localStorage.getItem("redirect");
        if (redirect) {
          window.localStorage.removeItem("redirect");
          this.$router.replace({ path: redirect });
        } else {
          this.$router.replace({ path: this.redirect });
        }
      } else {
        if (this.redirect !== "/signout-callback-oidc") {
          window.localStorage.setItem("redirect", this.redirect);
        }
        await this.$auth.login();
      }
    } catch (e) {
      console.error(e);
      this.authenticating = false;
    }
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
